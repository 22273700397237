.member-row {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    margin-left: .25rem;
    text-overflow: ellipsis ellipses;
    background-color: backgroundColor;
    padding: .25rem .75rem;
    border-radius: 5px;
}

.member-row-hover:hover {
    background-color: rgba(196, 196, 196, 0.25);
}

span.show-more {
    padding: .25rem .25rem;
    border-radius: 5px;
}

span.show-more:hover {
    background-color: rgba(196, 196, 196, 0.25);
}